import React, { useEffect, useState } from 'react';
import { CustomerForm } from './CustomerForm';

import axios from 'axios';
import { Customer } from '../../types';
import { fetchCustomer, persistCustomer } from '../../api/customerApi';
import { useNavigate, useParams } from 'react-router-dom';
import useEscapeKey from '../../hooks/useEscapeKey';

const CustomerContainer = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [customer, setCustomer] = useState<Customer>();

    useEffect(() => {
        if (id) {
            fetchCustomer(id)
                .then((customer) => {
                    setCustomer(customer);
                })
                .catch((error) => console.error('Error fetching customer data:', error));
        }
    }, [id]);

    const handleSave = async (customer: Customer) => {
        try {
            customer = await persistCustomer(customer);
            console.log('Success:', customer);
            navigate(`/customers`);
        } catch (error) {
            console.error('Error saving the customer:', error);
        }
    };

    const handleCancel = () => {
        navigate(`/customers`);
    };

    useEscapeKey(handleCancel);

    return (
        <div>
            <div className="row">
                <div className="col text-start">
                    <h2 className="display-6 mb-3">{customer ? customer.company : 'New Customer'}</h2>
                </div>
                <hr />
            </div>
            <div className="row">
                <div className="container col-8 ">
                    <CustomerForm customer={customer} onSave={handleSave} onCancel={handleCancel} />
                </div>
                <div className="container col-4 "></div>
            </div>
        </div>
    );
};

export default CustomerContainer;
