import axios from 'axios';

export function createTrelloCard(name: string, desc: string) {
    const key = process.env.REACT_APP_TRELLO_API_KEY;
    const token = process.env.REACT_APP_TRELLO_API_TOKEN;
    const id = process.env.REACT_APP_TRELLO_LIST_ID;

    if (!key || !token || !id) {
        // TODO specific envar to switch the feature off?
        return;
    }

    const url = `https://api.trello.com/1/cards?key=${key}&token=${token}&idList=${id}&name=${encodeURIComponent(
        name,
    )}&desc=${encodeURIComponent(desc)}`;

    axios
        .post(url)
        .then((data) => console.log('Card created:', data))
        .catch((error) => console.error('Error creating card:', error));
}
