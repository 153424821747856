import React, { useState, useEffect } from 'react';
import { Project, Customer, ProjectFormValues } from '../../types';
import { format } from 'date-fns';

interface ProjectFormProps {
    project?: Project; // Optional, for editing
    customers: Customer[]; // List of customers for the dropdown
    onSave: (project: ProjectFormValues) => void; // Callback to save the project
    onCancel: () => void; // Callback to close the form
    onChange: () => void; // Callback to handle changes
}

const ProjectForm: React.FC<ProjectFormProps> = ({ project, customers, onSave, onCancel, onChange }) => {
    const initialState = project
        ? {
              ...project,
              customerId: project.customer?.id || 0, // TODO fix this
              billingDate: format(new Date(project.billingDate), 'yyyy-MM-dd'),
              paymentDate: format(new Date(project.paymentDate), 'yyyy-MM-dd'),
          }
        : {
              customerId: 0,
              name: '',
              subtotal: 0,
              billingDate: format(new Date(), 'yyyy-MM-dd'),
              paymentDate: format(new Date(), 'yyyy-MM-dd'),
              isBilled: false,
              isPayed: false,
              isSubtotalConfirmed: true,
              purchaseOrder: '',
              orderedBy: '',
              emailOrderedBy: '',
              morePoDetails: '',
              notes: '',
          };

    const [formData, setFormData] = useState<ProjectFormValues>(initialState);
    const [totalInvoice, setTotalInvoice] = useState<number>(0);
    const [totalVAT, setTotalVAT] = useState<number>(0);
    const [totalTax, setTotalTax] = useState<number>(0);
    const [netBenefit, setNetBenefit] = useState<number>(0);

    useEffect(() => {
        const subtotal = parseFloat(formData.subtotal.toString());
        const customer = customers.find((customer) => customer.id === formData.customerId);
        const vat = customer?.vatPercentage ? (subtotal * customer.vatPercentage) / 100 : 0;
        const invoice = subtotal + vat;
        const tax = subtotal * 0.3;
        const net = subtotal - tax;

        setTotalInvoice(invoice);
        setTotalVAT(vat);
        setTotalTax(tax);
        setNetBenefit(net);
    }, [formData.subtotal, formData.customerId]);

    useEffect(() => {
        if (project) {
            setFormData({
                ...project,
                customerId: project.customer?.id || 0, // TODO fix this
                billingDate: format(new Date(project.billingDate), 'yyyy-MM-dd'),
                paymentDate: format(new Date(project.paymentDate), 'yyyy-MM-dd'),
            });
        }
    }, [project]); // Only reset form data when the project prop changes

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const target = event.target as HTMLInputElement; // Safely assume all elements as HTMLInputElement
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === 'customerId') {
            const selectedCustomerId = parseInt(value.toString());
            const customer = customers.find((customer) => customer.id === selectedCustomerId);
            if (customer) {
                setFormData((prev) => ({
                    ...prev,
                    customerId: selectedCustomerId,
                    paymentDate: format(calculatePaymentDate(formData.billingDate, customer), 'yyyy-MM-dd'),
                    morePoDetails: customer.defaultPoDetails?.toString() || '',
                    orderedBy: customer.contact?.toString() || '',
                    emailOrderedBy: customer.email?.toString() || '',
                }));
            }
        } else if (name === 'billingDate') {
            const customer = customers.find((customer) => customer.id === formData.customerId);
            setFormData((prev) => ({
                ...prev,
                billingDate: value.toString(),
                paymentDate: customer
                    ? format(calculatePaymentDate(value.toString(), customer), 'yyyy-MM-dd')
                    : 'error',
            }));
        } else {
            setFormData((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
        onChange();
    };

    function calculatePaymentDate(billingDate: string, customer: Customer) {
        const calculatedPaymentDate = new Date(billingDate);
        calculatedPaymentDate.setDate(calculatedPaymentDate.getDate() + customer.invoiceDueDays);
        return calculatedPaymentDate;
    }

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        onSave(formData);
    };

    return (
        <div className="row">
            <div className="col-8">
                <form onSubmit={handleSubmit} className="form">
                    <div className="mb-3">
                        <label htmlFor="customer">Customer</label>

                        <select
                            name="customerId"
                            value={formData.customerId}
                            onChange={handleChange}
                            className="form-control"
                        >
                            <option key="0" value="0">
                                -- Select Customer --
                            </option>
                            {customers.length != 0 ? (
                                customers.map((customer) => (
                                    <option key={customer?.id} value={customer?.id}>
                                        {customer?.company}
                                    </option>
                                ))
                            ) : (
                                <option value={0}>No customers available</option>
                            )}
                        </select>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="name">Project Name</label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="col  mb-3">
                        <label htmlFor="purchaseOrder">Purchase Order Code</label>
                        <input
                            type="string"
                            name="purchaseOrder"
                            value={formData.purchaseOrder}
                            onChange={handleChange}
                            className="form-control"
                        />
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            <label htmlFor="orderedBy">Ordered By</label>
                            <input
                                type="string"
                                name="orderedBy"
                                value={formData.orderedBy}
                                onChange={handleChange}
                                className="form-control"
                            />
                        </div>
                        <div className="col">
                            <label htmlFor="emailOrderedBy">Email</label>
                            <input
                                type="email"
                                name="emailOrderedBy"
                                value={formData.emailOrderedBy}
                                onChange={handleChange}
                                className="form-control"
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <div className="col mb-3">
                        <label htmlFor="morePoDetails">More PO Details</label>
                        <textarea
                            name="morePoDetails"
                            value={formData.morePoDetails}
                            onChange={handleChange}
                            className="form-control"
                            rows={3}
                        />
                    </div>
                    <div className="row">
                        <div className="col mb-3">
                            <label htmlFor="subtotal">Subtotal</label>
                            <div className="input-group mb-3">
                                <input
                                    type="number"
                                    name="subtotal"
                                    value={formData.subtotal}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                />
                                <span className="input-group-text">€</span>
                            </div>
                        </div>
                        <div className="col mb-3">
                            <label htmlFor="billingDate">Billing Date</label>
                            <input
                                type="date"
                                name="billingDate"
                                value={formData.billingDate}
                                onChange={handleChange}
                                className="form-control"
                                required
                            />
                        </div>
                        <div className="col mb-3">
                            <label htmlFor="paymentDate">Payment Date</label>
                            <input
                                type="date"
                                name="paymentDate"
                                value={formData.paymentDate}
                                onChange={handleChange}
                                className="form-control"
                            />
                        </div>
                    </div>

                    <div className="form-check">
                        <label>
                            <input
                                type="checkbox"
                                name="isBilled"
                                className="form-check-input"
                                checked={formData.isBilled}
                                onChange={handleChange}
                            />
                            Is Billed
                        </label>
                    </div>
                    <div className="form-check">
                        <label>
                            <input
                                type="checkbox"
                                name="isPayed"
                                className="form-check-input"
                                checked={formData.isPayed}
                                onChange={handleChange}
                            />
                            Is Payed
                        </label>
                    </div>
                    <div className="mb-3 form-check">
                        <label>
                            <input
                                type="checkbox"
                                name="isSubtotalConfirmed"
                                className="form-check-input"
                                checked={formData.isSubtotalConfirmed}
                                onChange={handleChange}
                            />
                            Is Subtotal Confirmed
                        </label>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="notes">Notes</label>
                        <textarea
                            name="notes"
                            value={formData.notes}
                            onChange={handleChange}
                            className="form-control"
                            rows={3}
                        ></textarea>
                    </div>
                    <div className="row mb-3">
                        <div className="col text-start">
                            <button type="submit" className="btn btn-primary">
                                Save Project
                            </button>
                            <button type="button" className="btn btn-secondary ms-2" onClick={onCancel}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            {formData.isBilled && (
                <div className="col-4 me-auto mt-4 text-end">
                    <div className="card ms-auto w-75">
                        <div className="card-header">Financial Summary</div>
                        <div className="card-body">
                            <p className="card-text">
                                <span className="title">Total Invoice:</span> {totalInvoice.toFixed(2)}€
                            </p>{' '}
                            <p className="card-text">
                                <span className="title">Total VAT:</span> {totalVAT.toFixed(2)}€
                            </p>
                            <p className="card-text">
                                <span className="title">Tax to Pay (30%):</span> {totalTax.toFixed(2)}€
                            </p>
                            <p className="card-text">
                                <span className="title">Net Benefit:</span> {netBenefit.toFixed(2)}€
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProjectForm;
