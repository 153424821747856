import React, { useEffect, useState } from 'react';
import { fetchAllProjects } from '../../api/projectApi';
import { Project } from '../../types';
import PaymentSummary from './PaymentSummary';
import 'bootstrap/dist/css/bootstrap.min.css';

const ProjectSummaryContainer: React.FC = () => {
    const [projects, setProjects] = useState<Project[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const getProjects = async () => {
            try {
                const data = await fetchAllProjects();
                setProjects(data);
            } catch (err) {
                setError('Error fetching projects');
            } finally {
                setLoading(false);
            }
        };

        getProjects();
    }, []);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div>
            <div className="row mb-5">
                <h2 className="display-6 mb-3">Payment Summary</h2>
                <hr />
            </div>

            <PaymentSummary projects={projects} />
        </div>
    );
};

export default ProjectSummaryContainer;
