import React, { useEffect, useState } from 'react';
import { fetchAllProjects } from '../../api/projectApi';
import { useNavigate } from 'react-router-dom';
import { Customer, Project } from '../../types';
import { Form } from 'react-bootstrap';
import './project-list.css';

interface ProjectListProps {
    showBilled: boolean;
    setShowBilled: (show: boolean) => void;
    showPayed: boolean;
    setShowPayed: (show: boolean) => void;
    selectedCustomer: number | '';
    setSelectedCustomer: (customer: number | '') => void;
}

const ProjectList: React.FC<ProjectListProps> = ({
    showBilled,
    setShowBilled,
    showPayed,
    setShowPayed,
    selectedCustomer,
    setSelectedCustomer,
}) => {
    const [projects, setProjects] = useState<Project[]>([]);
    const [uniqueCustomers, setUniqueCustomers] = useState<{ [key: number]: Customer }>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');

    const navigate = useNavigate();

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const allProjects = await fetchAllProjects();
                setProjects(allProjects); // Add type assertion here
                const customers = allProjects.reduce(
                    (acc, project) => {
                        acc[project.customer.id as number] = project.customer;
                        return acc;
                    },
                    {} as { [key: number]: Customer },
                );
                setUniqueCustomers(customers);

                setLoading(false);
            } catch (error) {
                setError('Failed to fetch customers.');
                setLoading(false);
            }
        };

        fetchProjects();
    }, []);

    const handleProjectClick = (project: Project) => {
        navigate(`/project/edit/${project.id}`);
    };

    const filteredProjects = projects.filter((project) => {
        if (!showBilled && project.isBilled) return false;
        if (!showPayed && project.isPayed) return false;
        if (selectedCustomer && project.customer.id !== selectedCustomer) return false;

        return true;
    });

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div>
            <div className="row">
                <div className="col text-start">
                    <h2 className="display-6 mb-3">Projects</h2>
                </div>
                <div className="col text-end mt-2">
                    <button title="New Project" className="btn btn-primary" onClick={() => navigate('/project/create')}>
                        +
                    </button>
                </div>
                <hr />
            </div>
            {filteredProjects.length > 0 ? (
                <div className="mb-3 text-end">
                    <div className="btn-group ">
                        <Form.Select
                            className="ms-2"
                            value={selectedCustomer}
                            onChange={(e) => setSelectedCustomer(Number(e.target.value) || '')}
                        >
                            <option value="">All Customers</option>
                            {Object.values(uniqueCustomers).map((customer) => (
                                <option key={customer.id} value={customer.id}>
                                    {customer.company}
                                </option>
                            ))}
                        </Form.Select>
                    </div>
                    <div className="btn-group ms-2 filter-button">
                        <button
                            className={`btn  ${showBilled ? 'btn-primary' : 'btn-secondary'}`}
                            onClick={() => setShowBilled(!showBilled)}
                        >
                            {showBilled ? 'Hide Billed' : 'Show Billed'}
                        </button>
                        <button
                            className={`btn  ${showPayed ? 'btn-primary' : 'btn-secondary'} `}
                            onClick={() => setShowPayed(!showPayed)}
                        >
                            {showPayed ? 'Hide Paid' : 'Show Paid'}
                        </button>
                    </div>
                </div>
            ) : (
                ''
            )}
            {filteredProjects.length > 0 ? (
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th>Customer Name</th>
                            <th>Project Name</th>
                            <th>Subtotal</th>
                            <th>Invoice Date</th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredProjects.map((project) => (
                            <tr key={project.id} onClick={() => handleProjectClick(project)}>
                                <td>{project.customer?.company}</td>
                                <td>{project.name}</td>
                                <td>{project.subtotal}€</td>
                                <td>{new Date(project.billingDate).toLocaleDateString()}</td>
                                <td>{project.isBilled && <span className="badge text-bg-warning">Billed</span>}</td>
                                <td>{project.isPayed && <span className="badge bg-success">Payed</span>}</td>
                                <td>
                                    {project.isSubtotalConfirmed || (
                                        <span className="badge rounded-pill bg-danger">{'TC'}</span>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No projects found</p>
            )}
        </div>
    );
};

export default ProjectList;
