import { Project, ProcessedData } from '../../types';

export const processData = (projects: Project[]): ProcessedData => {
    const data: ProcessedData = {};

    projects.forEach((project) => {
        const customer = project.customer.company;
        const month = new Date(project.paymentDate).toLocaleString('default', { month: 'short', year: 'numeric' });
        const subtotal = parseFloat(project.subtotal.toString());
        const total = subtotal + (subtotal * project.customer.vatPercentage) / 100;

        if (!data[customer]) {
            data[customer] = {};
        }

        if (!data[customer][month]) {
            data[customer][month] = {
                projects: [],
                total: 0,
                vat: 0,
                tax: 0,
                netBenefit: 0,
                allBilled: true,
                allPaid: true,
            };
        }

        data[customer][month].projects.push(project);
        data[customer][month].total += total;
        data[customer][month].vat += (parseFloat(project.subtotal.toString()) * project.customer.vatPercentage) / 100;
        data[customer][month].tax += parseFloat(project.subtotal.toString()) * 0.3;

        if (!project.isBilled) {
            data[customer][month].allBilled = false;
        }

        if (!project.isPayed) {
            data[customer][month].allPaid = false;
        }
    });

    // Calculate net benefit after processing all projects
    for (const customer in data) {
        for (const month in data[customer]) {
            const { total, vat, tax } = data[customer][month];
            data[customer][month].netBenefit = total - vat - tax;
        }
    }

    return data;
};
