import React, { useEffect, useState } from 'react';
import { Customer } from '../../types';
import { fetchAllCustomers, persistCustomer } from '../../api/customerApi';
import { Link, useNavigate } from 'react-router-dom';

const CustomerList: React.FC = () => {
    const [customers, setCustomers] = useState<Customer[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');

    const navigate = useNavigate();

    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const customersList = await fetchAllCustomers();
                setCustomers(customersList);
                setIsLoading(false);
            } catch (error) {
                setError('Failed to fetch customers.');
                setIsLoading(false);
            }
        };

        fetchCustomers();
    }, []);

    const handleCustomerClick = (customer: Customer) => {
        navigate(`/customer/edit/${customer.id}`);
    };

    const handleClose = () => {
        navigate(`/customers`);
    };

    const handleSave = async (updatedCustomer: Customer): Promise<void> => {
        // Assuming the backend uses PUT to update a customer and responds with the updated customer data
        try {
            updatedCustomer = await persistCustomer(updatedCustomer);

            // Update the list with the new customer data
            setCustomers(customers.map((customer) => (customer.id == updatedCustomer.id ? updatedCustomer : customer)));
        } catch (error) {
            setError('Failed to update customer.');
        }
    };

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div>
            <div className="row">
                <div className="col text-start">
                    <h2 className="display-6 mb-3">Customers</h2>
                </div>
                <div className="col text-end mt-2">
                    <button
                        title="New Customer"
                        className="btn btn-primary"
                        onClick={() => navigate('/customer/create')}
                    >
                        +
                    </button>
                </div>
                <hr />
            </div>
            {customers.length > 0 ? (
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Address</th>
                            <th>Invoice Due Days</th>
                        </tr>
                    </thead>
                    <tbody>
                        {customers.map((customer) => (
                            <tr key={customer.id} onClick={() => handleCustomerClick(customer)}>
                                <td>{customer.company}</td>
                                <td>{customer.email}</td>
                                <td>{customer.postalAddress}</td>
                                <td>{customer.invoiceDueDays}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No customers found</p>
            )}
        </div>
    );
};

export default CustomerList;
