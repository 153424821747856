import axios from 'axios';
import { Customer } from '../types';

const backendHost = process.env.REACT_APP_BACKEND_URL;

export async function persistCustomer(customer: Customer) {
    const endpoint = customer.id ? `${backendHost}/customers/${customer.id}` : `${backendHost}/customers`;
    const method = customer.id ? 'put' : 'post';

    const response = await axios[method](endpoint, customer);
    return response.data;
}

export const fetchAllCustomers = async () => {
    const response = await axios.get<Customer[]>(`${backendHost}/customers`);
    return response.data;
};

export const fetchCustomer = async (id: string) => {
    const response = await axios.get<Customer>(`${backendHost}/customers/${id}`);
    return response.data;
};
