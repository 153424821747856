import React, { useState, useEffect } from 'react';
import { Customer } from '../../types';
import { Tooltip } from 'bootstrap';

interface CustomerFormProps {
    customer?: Customer; // Optional customer for editing
    onSave: (customer: Customer) => Promise<void>; // Callback to save the customer
    onCancel: () => void; // Callback to cancel editing
}

export const CustomerForm: React.FC<CustomerFormProps> = ({ customer, onSave, onCancel }) => {
    const [formData, setFormData] = useState<Customer>({
        company: '',
        contact: '',
        email: '',
        postalAddress: '',
        vatPercentage: 0,
        invoiceDueDays: 15, // Default invoice due days
        defaultPoDetails: '',
    });

    useEffect(() => {
        if (customer) {
            setFormData(customer); // Pre-fill form when editing
        }
    }, [customer]);

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        onSave(formData);
    };

    useEffect(() => {
        // Initialize all tooltips within this component
        const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        const tooltipList = tooltipTriggerList.map((tooltipTriggerEl) => new Tooltip(tooltipTriggerEl));

        // Clean up tooltips on component unmount
        return () => {
            tooltipList.forEach((tooltip) => tooltip.dispose());
        };
    }, []);
    return (
        <form onSubmit={handleSubmit} className="mt-3">
            <div className="mb-3">
                <label htmlFor="company">Company name</label>
                <input
                    type="text"
                    className="form-control"
                    id="company"
                    name="company"
                    value={formData.company}
                    onChange={handleChange}
                    autoComplete="off"
                    required
                />
            </div>
            <div className="mb-3 row">
                <div className="col">
                    <label htmlFor="contact">
                        Contact person
                        <i
                            className="bi-info-circle-fill info-icon  mb-5"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Default value for each new project"
                        ></i>
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="contact"
                        name="contact"
                        value={formData.contact}
                        onChange={handleChange}
                        autoComplete="off"
                    />
                </div>
                <div className="col">
                    <label htmlFor="email">
                        Contact Email
                        <i
                            className="bi-info-circle-fill info-icon  mb-5"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Default value for each new project"
                        ></i>
                    </label>
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        autoComplete="off"
                    />
                </div>
            </div>
            <div className="mb-3">
                <label htmlFor="postalAddress">Postal Address</label>
                <textarea
                    className="form-control"
                    id="postalAddress"
                    name="postalAddress"
                    rows={3}
                    value={formData.postalAddress}
                    onChange={handleChange}
                    autoComplete="off"
                    required
                />
            </div>

            <div className="mb-3 row">
                <div className="col">
                    <label htmlFor="invoiceDueDays">VAT</label>
                    <div className="input-group">
                        <input
                            type="number"
                            className="form-control"
                            id="vatPercentage"
                            name="vatPercentage"
                            value={formData.vatPercentage}
                            onChange={handleChange}
                            required
                        />
                        <span className="input-group-text">%</span>
                    </div>
                </div>
                <div className="col">
                    <label htmlFor="invoiceDueDays">Invoice Due Days</label>
                    <input
                        type="number"
                        className="form-control"
                        id="invoiceDueDays"
                        name="invoiceDueDays"
                        value={formData.invoiceDueDays}
                        onChange={handleChange}
                        autoComplete="off"
                        required
                    />
                </div>
            </div>
            <div className="mb-3 ">
                <label htmlFor="defaultPoDetails">
                    PO Details
                    <i
                        className="bi-info-circle-fill info-icon  mb-5"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Default value for each new project"
                    ></i>
                </label>
                <textarea
                    rows={3}
                    className="form-control"
                    id="defaultPoDetails"
                    name="defaultPoDetails"
                    value={formData.defaultPoDetails}
                    onChange={handleChange}
                    autoComplete="off"
                />
            </div>
            <button type="submit" className="btn btn-primary">
                Save Customer
            </button>
            <button type="button" className="btn btn-secondary ms-2" onClick={onCancel}>
                Cancel
            </button>
        </form>
    );
};
