import React, { useEffect, useState } from 'react';
import { Table, OverlayTrigger, Popover } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Project, ProcessedData } from '../../types';
import { processData } from './ProcessData';
import 'bootstrap/dist/css/bootstrap.min.css';
import './paymetnsumary.css';

interface PaymentSummaryProps {
    projects: Project[];
}

const PaymentSummary: React.FC<PaymentSummaryProps> = ({ projects }) => {
    const [data, setData] = useState<ProcessedData>({});
    const [months, setMonths] = useState<string[]>([]);
    const [showTooltip, setShowTooltip] = useState<string | null>(null);

    useEffect(() => {
        const processedData = processData(projects);
        setData(processedData);

        const uniqueMonths = [
            ...new Set(
                projects.map((project) =>
                    new Date(project.paymentDate).toLocaleString('default', { month: 'short', year: 'numeric' }),
                ),
            ),
        ];
        setMonths(uniqueMonths);

        const handleClickOutside = (event: MouseEvent) => {
            const popoverElement = document.querySelector('.popover.show');
            if (popoverElement && !popoverElement.contains(event.target as Node)) {
                setShowTooltip(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [projects]);

    const renderTooltip = (projects: Project[]) => (
        <Popover>
            <Popover.Body>
                {projects.map((project, index) => (
                    <Link
                        className="popover-mylink"
                        to={`/project/edit/${project.id}`}
                        key={index}
                        style={{
                            fontWeight: project.isBilled ? 'bold' : 'normal',
                            color: project.isPayed ? 'green' : 'black',
                        }}
                    >
                        {project.name} -{' '}
                        {parseFloat(project.subtotal.toString()) +
                            (project.subtotal * project.customer.vatPercentage) / 100}
                        €
                    </Link>
                ))}
            </Popover.Body>
        </Popover>
    );

    const handleTooltipToggle = (key: string) => {
        setShowTooltip(showTooltip === key ? null : key);
    };

    return (
        <div className="row text-end">
            <Table bordered hover>
                <thead>
                    <tr>
                        <th className="text-start">Customer</th>
                        {months.map((month) => (
                            <th key={month}>{month}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(data).map((customer) => (
                        <tr key={customer}>
                            <td className="text-start">{customer}</td>
                            {months.map((month) => (
                                <td key={month}>
                                    {data[customer][month] ? (
                                        <OverlayTrigger
                                            trigger="click"
                                            placement="top"
                                            overlay={renderTooltip(data[customer][month].projects)}
                                            show={showTooltip === `${customer}-${month}`}
                                            onToggle={() => handleTooltipToggle(`${customer}-${month}`)}
                                        >
                                            <span
                                                style={{
                                                    fontWeight: data[customer][month].allBilled ? 'bold' : 'normal',
                                                    color: data[customer][month].allPaid ? 'green' : 'black',
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                {data[customer][month].total}€
                                            </span>
                                        </OverlayTrigger>
                                    ) : (
                                        ''
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>

                <tbody className="title">
                    <tr>
                        <td>Total Payment</td>
                        {months.map((month) => {
                            const total = Object.keys(data).reduce((sum, customer) => {
                                return sum + (data[customer][month] ? data[customer][month].total : 0);
                            }, 0);
                            return (
                                <td className="fs-5" key={month}>
                                    {total}€
                                </td>
                            );
                        })}
                    </tr>
                    <tr>
                        <td>Total VAT</td>
                        {months.map((month) => {
                            const totalVat = Object.keys(data).reduce((sum, customer) => {
                                return sum + (data[customer][month] ? data[customer][month].vat : 0);
                            }, 0);
                            return <td key={month}>{totalVat.toFixed(2)}€</td>;
                        })}
                    </tr>
                    <tr>
                        <td>Total Tax (30%)</td>
                        {months.map((month) => {
                            const totalTax = Object.keys(data).reduce((sum, customer) => {
                                return sum + (data[customer][month] ? data[customer][month].tax : 0);
                            }, 0);
                            return <td key={month}>{totalTax.toFixed(2)}€</td>;
                        })}
                    </tr>
                    <tr>
                        <td>Net Benefit</td>
                        {months.map((month) => {
                            const netBenefit = Object.keys(data).reduce((sum, customer) => {
                                return sum + (data[customer][month] ? data[customer][month].netBenefit : 0);
                            }, 0);
                            return (
                                <td className="fs-5" key={month}>
                                    {netBenefit.toFixed(2)}€
                                </td>
                            );
                        })}
                    </tr>
                </tbody>
            </Table>
        </div>
    );
};

export default PaymentSummary;
