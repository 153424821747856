import { useEffect, useState } from 'react';
import { Customer, Project, ProjectFormValues } from '../../types';
import { useParams } from 'react-router-dom';
import { fetchProject, persistProject } from '../../api/projectApi';
import { useNavigate } from 'react-router-dom';
import ProjectForm from './ProjectForm';
import { fetchAllCustomers } from '../../api/customerApi';
import useEscapeKey from '../../hooks/useEscapeKey';
import { createTrelloCard } from '../../api/trelloApi';

const ProjectContainer = () => {
    const [error, setError] = useState<string>('');
    const [customers, setCustomers] = useState<Customer[]>([]); // Create a state for customers
    const { id } = useParams();
    const navigate = useNavigate();
    const [project, setProject] = useState<Project>();
    const [projectUnsaved, setProjectUnsaved] = useState<boolean>(false);

    useEffect(() => {
        fetchAllCustomers().then((data) => {
            setCustomers(data); // Set the fetched customers to the state
        });
    }, []);

    useEffect(() => {
        if (id) {
            fetchProject(id)
                .then((project) => {
                    setProject(project);
                })
                .catch((error) => console.error('Error fetching customer data:', error));
        } else {
            setProject(undefined);
        }
    }, [id]);

    const handleInvoice = () => {
        navigate(`/project/invoice/${project?.id}`);
    };

    const handleSave = async (project: ProjectFormValues) => {
        try {
            project = await persistProject(project);
            console.log('Success:', project);
            createTrelloCard(project?.name || 'New Project', project?.notes || '');
            setProjectUnsaved(false);
            navigate(`/projects`);
        } catch (error) {
            console.error('Error saving the customer:', error);
            setError('Error saving the customer');
        }
    };

    const handleCancel = () => {
        navigate(`/projects`);
    };

    const handleChange = () => {
        setProjectUnsaved(true);
    };

    useEscapeKey(handleCancel);

    return (
        <div>
            <div className="row">
                <div className="col-9 text-start">
                    <h2 className="display-6 mb-3">{project ? project.name : 'New Project'}</h2>
                </div>
                <div className="col-3 text-end">
                    {project && project.id ? (
                        <button
                            type="button"
                            disabled={projectUnsaved}
                            className="btn btn-primary mt-2 "
                            onClick={handleInvoice}
                        >
                            Generate Invoice
                        </button>
                    ) : (
                        ''
                    )}
                </div>
                <hr />
            </div>

            <ProjectForm
                project={project}
                customers={customers}
                onSave={handleSave}
                onCancel={handleCancel}
                onChange={handleChange}
            />
        </div>
    );
};

export default ProjectContainer;
