import './App.css';
import CustomerContainer from './components/CustomerForm/CustomerContainer';
import ProjectContainer from './components/ProjectForm/ProjectContainer';
import CustomerList from './components/CustomerList/CustomerList';
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import ProjectList from './components/ProjectList/ProjectList';
import InvoiceContainer from './components/Invoice/InvoiceContainer';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Ensure Popper.js is included
import 'bootstrap-icons/font/bootstrap-icons.css';
import ProjectSummaryContainer from './components/PaymentSummary/PaymentSummaryContainer';
import { useState } from 'react';

function App() {
    const [showBilled, setShowBilled] = useState<boolean>(false);
    const [showPayed, setShowPayed] = useState<boolean>(false);
    const [selectedCustomer, setSelectedCustomer] = useState<number | ''>('');

    return (
        <div className="App">
            <main>
                <header>
                    <div className="px-3 py-2 text-bg-dark border-bottom">
                        <div className="container">
                            <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
                                <Link
                                    to="/"
                                    className="d-flex align-items-center my-2 my-lg-0 me-lg-auto text-white text-decoration-none"
                                >
                                    <svg
                                        className="bi me-2 svg-icon"
                                        width="40"
                                        height="32"
                                        role="img"
                                        aria-label="Bootstrap"
                                    >
                                        <use xlinkHref="/icons.svg#bootstrap"></use>
                                    </svg>
                                </Link>
                                <ul className="nav col-12 col-lg-auto my-2 justify-content-center my-md-0 text-small">
                                    <li>
                                        <Link to="/projects" className="nav-link text-white">
                                            <svg className="bi d-block mx-auto mb-1" width="24" height="24">
                                                <use xlinkHref="/icons.svg#table"></use>
                                            </svg>
                                            Projects
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/customers" className="nav-link text-white">
                                            <svg className="bi d-block mx-auto mb-1" width="24" height="24">
                                                <use xlinkHref="/icons.svg#people-circle"></use>
                                            </svg>
                                            Customers
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/projects/payment-summary" className="nav-link text-white">
                                            <svg className="bi d-block mx-auto mb-1" width="24" height="24">
                                                <use xlinkHref="/icons.svg#cash-coin"></use>
                                            </svg>
                                            Payment Summary
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </header>
            </main>
            <div className="container">
                <div className="row mt-4 mb-4">
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <ProjectList
                                    showBilled={showBilled}
                                    setShowBilled={setShowBilled}
                                    showPayed={showPayed}
                                    setShowPayed={setShowPayed}
                                    selectedCustomer={selectedCustomer}
                                    setSelectedCustomer={setSelectedCustomer}
                                />
                            }
                        />
                        <Route path="/customers" element={<CustomerList />} />
                        <Route path="/customer/create" element={<CustomerContainer />} />
                        <Route path="/customer/edit/:id" element={<CustomerContainer />} />
                        <Route path="/project/create" element={<ProjectContainer />} />
                        <Route path="/project/edit/:id" element={<ProjectContainer />} />
                        <Route
                            path="/projects"
                            element={
                                <ProjectList
                                    showBilled={showBilled}
                                    setShowBilled={setShowBilled}
                                    showPayed={showPayed}
                                    setShowPayed={setShowPayed}
                                    selectedCustomer={selectedCustomer}
                                    setSelectedCustomer={setSelectedCustomer}
                                />
                            }
                        />
                        <Route path="/project/invoice/:id" element={<InvoiceContainer />} />
                        <Route path="/projects/payment-summary" element={<ProjectSummaryContainer />} />
                    </Routes>
                </div>
            </div>
        </div>
    );
}

export default App;
