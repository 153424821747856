import { useEffect } from 'react';

function useEscapeKey(onEscape: () => void) {
    useEffect(() => {
        const handleKeyDown = (event: { key: string }) => {
            if (event.key === 'Escape') {
                onEscape();
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [onEscape]);
}

export default useEscapeKey;
