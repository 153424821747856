import { useEffect, useState } from 'react';
import Invoice from './Invoice';
import { Project } from '../../types';
import { useParams } from 'react-router-dom';
import { fetchProject } from '../../api/projectApi';

const InvoiceContainer = () => {
    const { id } = useParams();
    const [project, setProject] = useState<Project>();

    useEffect(() => {
        if (id) {
            fetchProject(id)
                .then((project) => {
                    setProject(project);
                })
                .catch((error) => console.error('Error fetching customer data:', error));
        } else {
            setProject(undefined);
        }
    }, [id]);

    return <div>{project && project.customer ? <Invoice customer={project.customer} project={project} /> : ''}</div>;
};

export default InvoiceContainer;
