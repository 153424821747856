import React, { useEffect, useRef, useState } from 'react';
import { Customer, Project } from '../../types';
import './Invoice.css';
import { format } from 'date-fns';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import useClickOutside from '../../hooks/useClickOutside';
import useEscapeKey from '../../hooks/useEscapeKey';
import { useNavigate } from 'react-router-dom';

interface InvoiceProps {
    customer: Customer;
    project: Project;
}

const Invoice: React.FC<InvoiceProps> = ({ customer, project }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [isInvoiceEditing, setIsInvoiceEditing] = useState(false);
    const [serviceText, setServiceText] = useState(project?.name);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const invoiceNumberRef = useRef<HTMLInputElement>(null);

    const formatDate = (date: Date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}${month}${day}`;
    };
    const [invoiceNumberText, setInvoiceNumberText] = useState(formatDate(new Date(project.billingDate)) + '01');

    useClickOutside(textAreaRef, () => setIsEditing(false), isEditing);
    useClickOutside(invoiceNumberRef, () => setIsInvoiceEditing(false), isInvoiceEditing);

    const navigate = useNavigate();
    const handleCancel = () => {
        navigate(`/project/edit/${project.id}`);
    };
    useEscapeKey(handleCancel);

    const handleDoubleClick = () => {
        setIsEditing(true);
    };

    const handleInvoiceDoubleClick = () => {
        setIsInvoiceEditing(true);
    };

    const convertNewlinesToBreaks = (text: string) => {
        return text.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ));
    };

    const calculateRows = (text: string) => {
        const lines = text.split('\n').length;
        return lines;
    };

    const formatCurrency = (amount: number) => {
        return `${amount.toLocaleString('de-DE', { minimumFractionDigits: 2 })}€`;
    };

    const vatRate = Number(customer.vatPercentage);
    const netAmount = Number(project.subtotal);
    const vatAmount = (netAmount * vatRate) / 100;
    const totalAmount = netAmount + vatAmount;
    const invoiceNumber = formatDate(new Date()) + '01';

    const generatePdf = async () => {
        const invoiceElement = document.getElementById('invoice');
        if (!invoiceElement) return;

        const canvas = await html2canvas(invoiceElement, { scale: 2 });
        const imgData = canvas.toDataURL('image/png', 0.8);
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const imgProps = pdf.getImageProperties(imgData);
        const imgWidth = pdfWidth - 30; // Subtracting margins
        const imgHeight = (imgProps.height * imgWidth) / imgProps.width;

        pdf.addImage(imgData, 'PNG', 15, 15, imgWidth, imgHeight, undefined, 'FAST');
        pdf.save(`${invoiceNumberText} - ${customer.company}.pdf`);
    };

    return (
        <div>
            <div className="container invoice d-flex flex-column justify-content-between" id="invoice">
                <div className="col6 flex-grow-1 min-vh-100">
                    <div className="row mb-3 ">
                        <div className="col text-start">
                            <img id="logo" src="/square_logo.png" alt="Logo" className="img-fluid" />
                        </div>
                        <div className="col text-end mt-2">
                            <p className="mt-5">
                                <span className="title">Mariana Rickmann PhD</span>
                                <br />
                                Herzog-Max-Straße 7
                                <br />
                                82140 Olching, Germany
                                <br />
                                mariana@marianarickmann.com <br />
                                Tax ID No 117/273/30382
                                <br />
                                VAT ID DE314302921
                            </p>
                        </div>
                    </div>
                    <div className="row m-2"></div>
                    <div className="row mb-4 mt-5">
                        <div className="col text-start">
                            <p className="title mb-3">Client</p>
                            <p className="mb-1">
                                {!project.purchaseOrder && customer.contact ? (
                                    <>
                                        {customer.contact}
                                        <br />
                                    </>
                                ) : (
                                    ''
                                )}

                                <span>{customer.company}</span>
                                <br />
                                {convertNewlinesToBreaks(customer.postalAddress)}

                                {/* {!project.purchaseOrder ? customer.email : ''} */}
                            </p>
                        </div>

                        <div className="col text-end">
                            <p>
                                <span className="title">Invoice No. </span>
                                {isInvoiceEditing ? (
                                    <input
                                        ref={invoiceNumberRef}
                                        type="text"
                                        className="form-control edit-input"
                                        value={invoiceNumberText}
                                        autoComplete="off"
                                        onChange={(e) => setInvoiceNumberText(e.target.value)}
                                        onBlur={() => setIsInvoiceEditing(false)} // Optional: End editing on blur
                                    />
                                ) : (
                                    <span className="ms-2" onDoubleClick={handleInvoiceDoubleClick}>
                                        {invoiceNumberText}
                                    </span>
                                )}
                            </p>
                            <p>{format(project.billingDate, 'dd MMM yyyy')}</p>
                            {project.purchaseOrder && (
                                <p className="mb-1">
                                    <br />
                                    <br />
                                    Purchase order: {project.purchaseOrder}
                                    <br />
                                    {project.orderedBy && (
                                        <>
                                            {`Ordered by: ${project.orderedBy}`}
                                            <br />
                                        </>
                                    )}
                                    {project.emailOrderedBy && (
                                        <>
                                            {`Email: ${project.emailOrderedBy}`}
                                            <br />
                                        </>
                                    )}
                                    {project.morePoDetails && (
                                        <>
                                            {convertNewlinesToBreaks(project.morePoDetails)}
                                            <br />
                                        </>
                                    )}
                                </p>
                            )}
                        </div>
                    </div>
                    <hr className="mt-5" />
                    <div className="row title bold">
                        <div className="col-8">Service</div>
                        <div className="col-2 text-end">VAT</div>
                        <div className="col-2 text-end">Amount</div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-8">
                            {isEditing ? (
                                <textarea
                                    ref={textAreaRef}
                                    rows={calculateRows(serviceText)}
                                    className="form-control edit-textarea"
                                    value={serviceText}
                                    onChange={(e) => setServiceText(e.target.value)}
                                    onBlur={() => setIsEditing(false)} // Optional: End editing on blur
                                />
                            ) : (
                                <p onDoubleClick={handleDoubleClick}>{convertNewlinesToBreaks(serviceText)}</p>
                            )}
                        </div>
                        <div className="col-2 text-end">{vatRate}%</div>
                        <div className="col-2 text-end">{formatCurrency(netAmount)}</div>
                    </div>
                    <hr />
                    <div className="row mb-3">
                        <div className="col text-end">
                            <div className="row">
                                <div className="col-6 text-start">Subtotal</div>
                                <div className="col-6 text-end">{formatCurrency(netAmount)}</div>
                            </div>

                            <div className="row">
                                <div className="col-6 text-start">VAT ({vatRate}%)</div>
                                <div className="col-6 text-end">{formatCurrency(vatAmount)}</div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-6 text-start title">Total</div>
                                <div className="col-6 text-end title bold">{formatCurrency(totalAmount)}</div>
                            </div>

                            <hr />
                        </div>
                    </div>

                    <div className={project.purchaseOrder ? 'payment-details-with-po' : 'payment-details'}>
                        <div className="row ">
                            <div className="col-7 text-start">
                                <p className="title bold mb-3">Payment details:</p>
                                <table className="table table-borderless ">
                                    <tbody>
                                        <tr>
                                            <td>Bank:</td>
                                            <td>Deutsche Kredit Bank AG</td>
                                        </tr>
                                        <tr>
                                            <td>IBAN:</td>
                                            <td>DE47 1203 0000 1201 7487 77</td>
                                        </tr>
                                        <tr>
                                            <td>BIC:</td>
                                            <td>BYLADEM1001</td>
                                        </tr>
                                        <tr>
                                            <td>Account owner:</td>
                                            <td>Mariana Rickmann Ashwell</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row  mb-5">
                            <div>
                                <p className="red fs-8">
                                    New bank account details. Please use the new bank account details for all future
                                    payments.
                                </p>
                                <p className="fs-8">
                                    The total amount is payable within {customer.invoiceDueDays} days of receipt of this
                                    invoice.
                                </p>
                            </div>
                        </div>
                        <div className="row ">
                            <div>
                                <p className="fs-8">
                                    You have received the listed services in accordance with our agreement. Unless
                                    otherwise stated, the service date corresponds to the invoice date.
                                </p>
                                <p className="fs-8">
                                    Please note the retention periods under tax law for this invoice: Private
                                    individuals 2 years, entrepreneurs 10 years.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row m-3 invoice">
                <div className="col text-center">
                    <button className="btn btn-primary" onClick={generatePdf}>
                        Download PDF
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Invoice;
